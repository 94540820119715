import './App.css';
import { useEffect } from "react";
import React, { useState } from 'react';
import fuzzyFilterFactory from "react-fuzzy-filter";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { FilterResults, changeInputValue } = fuzzyFilterFactory();

function App() {

  const [items, setItems] = useState([]);
  const fuseConfig = {
    keys: ["item_title", "name"],
    threshold: 0.2,
    tokenize: true,
    matchAllTokens: true
  };
  var textInput = null;
  const [query, setQuery] = useState("");
  const [showList, setShowList] = useState(false);
  const [shoppingListPrice, setShoppingListPrice] = useState(0);
  const [fetchedItems, setFetchedItems] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  var listId = searchParams.get('list');
  if (listId !== null) {
    listId = listId.toLowerCase();
  }

  useEffect(() => {
    getData();
    try {
      textInput.focus();
    } catch (e) { }
  }, []);
  useEffect(() => {
    const timeOutId = setTimeout(() => changeInputValue(query.trim()), 300);
    return () => clearTimeout(timeOutId);
  }, [query]);
  useEffect(() => {
    if (items.length) {
      localStorage.setItem('shoppingList', JSON.stringify(items.filter(item => item.quantity > 0)));
    }
    calculateTotalPrice();
  }, [items]);
  useEffect(() => {
    if (textInput) {
      textInput.value = query;
      textInput.focus();
    }
  }, [showList]);
  useEffect(() => {
    if (listId !== null) {
      fetch(`https://shy-thunder-61b1.testkitchen.workers.dev/${listId}`, {
        method: 'GET',
      }).then(r => r.text().then((list) => {
        const jsonList = JSON.parse(list).map((text, index) => ({
          'id': index, 'text': text, 'completed': false
        }));
        let stored = localStorage.getItem(listId);
        if (stored !== null) {
          stored = JSON.parse(stored);
          setFetchedItems(stored);
        } else {
          setFetchedItems(jsonList);
        }
      }));
    }
  }, [listId]);
  useEffect(() => {
    if (fetchedItems.length) {
      localStorage.setItem(listId, JSON.stringify(fetchedItems));
    }
  }, [fetchedItems]);

  const getData = async () => {
    fetch('https://s3.us-east.cloud-object-storage.appdomain.cloud/tj-store-78/inventory.json',
      {
        headers:
          { 'Cache-Control': 'max-age=28800' }
      }
    )
      .then(r => r.json().then((data) => {
        const shoppingList = JSON.parse(localStorage.getItem('shoppingList'));
        if (shoppingList) {
          data.forEach((item, index) => {
            const itemFound = shoppingList.find(x => x.name === item.name);
            if (itemFound) {
              data[index].quantity = itemFound.quantity;
            }
          });
        }
        setItems(data);
      }));
  }
  const addToList = (event, item) => {
    const copy = [...items];
    const foundItem = copy.find(x => x.name === item.name);
    if (foundItem.quantity !== undefined) {
      foundItem.quantity += 1;
    } else {
      foundItem.quantity = 1;
    }
    setItems(copy);
    if (foundItem.quantity === 1) {
      toast.success(`"${item.item_title}"\n added to shopping list`);
    }
  }
  const removeFromList = (event, item) => {
    const copy = [...items];
    const foundItem = copy.find(x => x.name === item.name);
    foundItem.quantity--;
    setItems(copy);
    if (foundItem.quantity <= 0) {
      toast.info(`"${item.item_title}"\n removed from shopping list`);
    }
  }
  const toggleList = () => {
    setShowList(!showList);
  }
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    for (const item of items.filter(item => item.quantity > 0)) {
      totalPrice += item.retail_price * item.quantity;
    }
    setShoppingListPrice(totalPrice.toFixed(2));
  }
  const clearQuery = (event) => {
    setQuery("");
    textInput.value = "";
    textInput.focus();
  }
  const uploadList = () => {
    fetch("https://shy-thunder-61b1.testkitchen.workers.dev/", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(items.filter(item => item.quantity > 0).map(item => item.name))
    }).then(r => r.text().then((listId) => {
      toast.info(`Shopping List ID: ${listId.toUpperCase()}`, {autoClose: false});
    }));
  };
  const toggleItem = (index) => {
    const newItems = [...fetchedItems];
    newItems[index].completed = !newItems[index].completed;
    setFetchedItems(newItems);
  };

  return (
    <div className="container mt-4" >
      <ToastContainer autoClose={1600} />
      <div>
        {fetchedItems.length > 0 &&
          <ul className="list list-group list-group-flush">
            {fetchedItems.map((item) => (
              <li
                className="list-group-item h1"
                key={item.id}
                onClick={() => toggleItem(item.id)}
                style={{
                  textDecoration: item.completed ? 'line-through' : 'none',
                  fontSize: 'calc(1.375rem + 4.5vw)'
                }}
              >
                {item.text}
              </li>
            ))}
          </ul>
        }
        {fetchedItems.length === 0 && !showList &&
          <div>
            <span className="mb-4 mt-4 h1">Grocery Search</span>
            <button type="button" className="btn btn-primary btn-sm float-end" onClick={toggleList}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            </button>
            <div id="inventory" className="mt-4">
              <div className="input-group mb-4">
                <input className="search form-control" placeholder="Search" ref={(e) => { textInput = e; }} onChange={e => setQuery(e.target.value)} />
                <button className="btn btn-secondary" type="button" onClick={clearQuery}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </button>
              </div>
              <div className="mb-4 mt-4"></div>
              <FilterResults items={items} fuseConfig={fuseConfig} defaultAllItems={false}>
                {filteredItems => {
                  return (
                    <ul className="list list-group list-group-flush">
                      <div>
                        {filteredItems.map(item => (
                          <li className="list-group-item" key={item.name}>
                            <h1 className="h4 title mb-0">{item.item_title}</h1>
                            <span className="name text mb-1 me-2">{item.name}</span>
                            <div>
                              <span className="price badge badge-pill bg-secondary me-1">{item.sales_size} {item.sales_uom_description}</span>
                              <span className="price badge badge-pill bg-secondary me-1">${(item.retail_price / item.sales_size).toFixed(2)} / {item.sales_uom_description}</span>
                              <button type="button" className="btn btn-primary btn-sm float-end mt-2" onClick={(event) => { addToList(event, item); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus" viewBox="0 0 16 16">
                                  <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                                  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                              </button>
                              {item.quantity > 0 &&
                                <button type="button" className="btn btn-outline btn-sm float-end disabled mt-2">
                                  {item.quantity}
                                </button>
                              }
                              {item.quantity > 0 &&
                                <button type="button" className="btn btn-danger btn-sm float-end mt-2" onClick={(event) => { removeFromList(event, item); }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-x" viewBox="0 0 16 16">
                                    <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z" />
                                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                  </svg>
                                </button>
                              }
                            </div>
                            <div className="mb-3">
                              <span className="price badge badge-pill bg-success me-1">${item.retail_price}</span>
                              {item.name.startsWith("Hol ") && <span className="price badge badge-pill bg-danger">Seasonal</span>}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  );
                }}
              </FilterResults>
            </div>
          </div>
        }
        {fetchedItems.length === 0 && showList &&
          <div>
            <span className="mb-4 mt-4 h1">Shopping List</span>
            <button type="button" className="btn btn-primary btn-sm float-end" onClick={toggleList}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
            <div id="shoppingList" className="mt-4">
              <ul className="list list-group list-group-flush">
                {items.filter(item => item.quantity > 0).length <= 0 &&
                  <li className="list-group-item">
                    <h4>
                      Your shopping list is empty.
                    </h4>
                  </li>
                }
                {items.filter(item => item.quantity > 0).map(item => (
                  <li className="list-group-item" key={item.name}>
                    <h1 className="h4 title mb-0">{item.item_title}</h1>
                    <span className="name text mb-1 me-2">{item.name}</span>
                    <div>
                      <span className="price badge badge-pill bg-secondary me-1">{item.sales_size} {item.sales_uom_description}</span>
                      <span className="price badge badge-pill bg-secondary me-1">${(item.retail_price / item.sales_size).toFixed(2)} / {item.sales_uom_description}</span>
                      <button type="button" className="btn btn-primary btn-sm float-end mt-2" onClick={(event) => { addToList(event, item); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus" viewBox="0 0 16 16">
                          <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </button>
                      {item.quantity > 0 &&
                        <button type="button" className="btn btn-outline btn-sm float-end disabled mt-2">
                          {item.quantity}
                        </button>
                      }
                      {item.quantity > 0 &&
                        <button type="button" className="btn btn-danger btn-sm float-end mt-2" onClick={(event) => { removeFromList(event, item); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-x" viewBox="0 0 16 16">
                            <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z" />
                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </button>
                      }
                    </div>
                    <div className="mb-3">
                      <span className="price badge badge-pill bg-success me-1">${item.retail_price}</span>
                      {item.name.startsWith("Hol ") && <span className="price badge badge-pill bg-danger">Seasonal</span>}
                    </div>
                  </li>
                ))}
                {items.filter(item => item.quantity > 0).length > 0 &&
                  <li className="list-group-item">
                    <span className="price badge badge-pill bg-success mt-4 float-end">
                      Total Price: ${shoppingListPrice}
                    </span>
                    <button type="button" className="btn btn-primary btn-sm mt-4" onClick={uploadList}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                      </svg>
                    </button>
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      </div >
    </div >
  );
}

export default App;
